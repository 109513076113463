<template lang="pug">
div
  Header
  .Page
    .container-logo.MeetingPage
      .p-3
        h5(
          v-if="message"
          style="text-align: center; color: firebrick;"
        ).buttonText No tienes habilitado el uso de la cámara y del video. Habilítalo y vuelve a intentarlo.
        div(
          v-if="messageTurn"
        )
          span(style="text-align: center")
            b-button(variant="bluecolmedica" block size="lg" type="button" @click="startTurn" style="width: fit-content;").text-center.AuthsBtn.mt-4.mb-5
              p.buttonText.AuthMeds.mb-0 Reintentar
          h5.mt-3(
            style="text-align: center; color: firebrick;"
          ).buttonText No fue posible establecer un turno. Por favor reinténtelo o comuníquese con el soporte técnico.
        div(v-if="!message && !messageTurn").position-relative.d-inline.text-center.display-dk
          img(:src="Conected").size_icon.mx-auto
          h5(style="font-size: 24px;").buttonText Estamos solicitando tu turno. Por favor espera.
</template>
<script>
import LogoColmedica from "@/assets/colmedica_logo.png";
import { mapActions, mapState } from "vuex";
import Conected from "@/assets/conected.gif";
import Header from "./components/Header";

export default {
  name: "BeforeTurn",
  data: () => ({
    message: false,
    messageTurn: false,
    LogoColmedica,
    Conected: Conected,
    errorCounter: 15,
    turnInfo: null
  }),

  components: {
    Header
  },

  created() {
    this.startTurn();
  },

  computed: {
    ...mapState({
      company: state => state.company,
      company_queue: state => state.company_queue,
      this_user_data: state => state.client_data,
      turn: state => state.turn,
      tramite_info: state => state.tramite_info,
      catch_error: state => state.catch_error,
      env: state => state.env
    }),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO_OA || this.LogoColmedica;
    }
  },

  methods: {
    ...mapActions({
      getTurn: "getTurn",
      cancelTurn: "cancelTurn",
      cleanTurn: "cleanTurn",
      putLog: "log/put"
    }),
    async startTurn() {
      try {
        this.messageTurn = false;
        if (this.errorCounter <= 0) this.errorCounter = 15;
        let turnInfo;
        if (!this.turnInfo) {
          turnInfo = await this.getTurn();
          this.putLog({
            name: "BeforeTurn. Obtiene un turno nuevo."
          });
          this.turnInfo = turnInfo;
        } else {
          this.putLog({
            name: "BeforeTurn. Continúa con el turno existente."
          });
          turnInfo = this.turnInfo;
        }
        console.log("QUERY")
        console.log(this.$route.query)
        this.$router.push({ name: "TurnoKiosco46", query: this.$route.query });
      } catch (error) {
        console.log("ERROR")
        console.log(error)
        // Bad data stored on LS
        if (error.message == "Invalid Tramite") {
          localStorage.clear();
          this.cancelTurn();
          return this.$router.push({path: "/fv_atril_video/", query: this.$route.query});
        }
        this.$rollbar.error("Turn request error", error);
        --this.errorCounter;
        if (this.errorCounter > 0) {
          setTimeout(() => this.startTurn(), 3000);
        } else {
          this.putLog({
            name:
              "BeforeTurn. Ha fallado la generación del turno en 15 intentos.",
            checked: false,
            message: error
          });
          this.messageTurn = true;
          this.cancelTurn();
          this.$rollbar.error("User cant take a turn after 3 tries");
        }
      }
    }
  }
};
</script>

<style scoped>
.container-logo {
  flex-direction: column;
}
.size_icon {
  width: 30%;
  max-width: 200px;
  text-align: center;
}

.MeetingPage {
  background-color: #f2f4f8;
  padding: 1.5rem;
  width: 100%;
  max-width: 600px;
  height: fit-content;
}

.display-dk {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.buttonText {
  font-size: 28px;
}

.AuthMeds {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 65px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.AuthsBtn {
  margin: 100px auto;
  width: 400px;
  padding: 9px 3.5rem;
  line-height: 65px;
  font-weight: 600;
}
</style>
